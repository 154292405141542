<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <a-row class="header-solid ant-card" :gutter="24">
   <a-table :data-source="data">
      <a-table-column
        key="id"
        title="ID"
        data-index="id"
      />
      <a-table-column
        key="user_id"
        title="نام"
        data-index="user_id"
      />
      <a-table-column
        key="name"
        title="نام پروژه"
        data-index="name"
      >

        <template slot-scope="name">
          {{name}}
        </template>
          {{data.name_fa}}
      </a-table-column>
    <a-table-column key="platforms" title="پلتفرم" data-index="platforms" />
    <a-table-column key="requests" title="درخواست ها" data-index="requests" />
      <a-table-column
        key="status"
        title="وضعیت"
        data-index="status"
      >
        <template slot-scope="status">
          <a-tag v-if="status == 'requested'" color="orange">
            در انتظار بررسی
          </a-tag>
          <a-tag v-if="status == 'in_progress'" color="green">
            در دست توسعه
          </a-tag>
        </template>
      </a-table-column> 
    <a-table-column key="action" title="انجام بدید">
      <template slot-scope="text, record">
        <span>
          <a>مسدود سازی 一 {{ record.firstName }}</a>
          <a-divider type="vertical" />
          <a>حذف</a>
          <a-divider type="vertical" />
          <router-link :to="'/admin/projects/modify/'+record.id"> ویرایش</router-link>
        </span>
      </template>
    </a-table-column>
  </a-table>
 
  </a-row>
</template>

<script>
	import Vue from 'vue'
 const data = [
];
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

	import { userService } from '../../../services/user.service';
	import { adminService } from '../../../services/admin.service';
	export default ({
		components: { 
		},
    created(){
      // form layout
      this.$form.layout = 'horizontal'
      
      // set admin user
      this.user = Vue.prototype.$me;

      // load user list
      adminService.getProjects().then(response => {    
        if (response.status == 200) {
          return response.json();            
        } else {
          alert('asd');
        }
      }).then((response) => {

        this.data = response.data.projects
        //var that = this;
    //
        //this.data.forEach(function(user,key){ 
        //  
        //});
        //response.data.groups.forEach(function(user,key){ 
        //  that.userGroups[key] = user.name;
        //});
      }).catch((e) => {
        console.log(e);
      });
    },
		data() {
			return {
        selectedRoles: [],
        checkNick: false,
        formItemLayout,
        formTailLayout,
        form: this.$form.createForm(this, { 
          name: 'dynamic_rule' 
        }),
        modifyUser: {},
        modifyModel: null,
        user: null, 
				data,
				userGroups: {},
				filteredTags: [],
				tagsFilterDropdownVisible: false,
			}
		},
		methods: {
      saveModifiedUser() {

        this.form.validateFields(err => {
          if (!err) {
            var user = {
              id: this.modifyUser.id,
              fullname: this.form.getFieldValue('fullname'),
              username: this.form.getFieldValue('username'),
              email: this.form.getFieldValue('email'),
              phone_number: this.form.getFieldValue('phone_number'),
              fullname: this.form.getFieldValue('fullname'),
              roles: this.selectedRoles
            }

            // load user list
            userService.saveUser(user).then(response => {    
              if (response.status == 200) {
                return response.json();            
              } else {
                alert('asd');
              }
            }).then((response) => {

              console.log(response)
          
            }).catch((e) => {
              console.log(e);
            });
          }
        });
      },
      modifyUserGroup(selectedRoles) {
        this.selectedRoles = selectedRoles
      },
      handleChange(e) {
        this.checkNick = e.target.checked;
        
      },
      displayModifyModal(user) {
        this.modifyUser = user;
        this.selectedRoles = Object.values(user.roles)
        this.modifyModel = true
      },
			toggleSelectedTags(oldTags, tag, checked) {
				let newTags = [...oldTags];
				if (checked) {
				newTags.push(tag);
				} else {
				newTags = newTags.filter(t => t != tag);
				}
				return newTags;
			},
		}
	})

</script>

<style lang="scss">
</style>